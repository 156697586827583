// This shares gate.js and main.js
import mitt from 'mitt';

export const bus = mitt();

export const LEVEL = Object.freeze({
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'danger',
  DANGER: 'danger',
});

export const BASE_STORE_DEF = {
  state: () => ({
    // [String, LEVEL, String]
    messages: [],
  }),

  mutations: {
    pushMessage(state, [message, level = LEVEL.INFO, title = undefined]) {
      if (!title) {
        title = message.split(' ').slice(0, 2).join(' ');
      }
      if (level === LEVEL.ERROR) {
        console.error(message);
      }
      state.messages.push([message, level, title]);
      // notify that store has a message to pop
      bus.emit('message');
    },
    popMessage(state) {
      state.messages.pop();
    },
  },

  actions: {
    async popMessage({state, commit}) {
      const msg = state.messages[this.state.messages.length - 1];
      commit('popMessage');
      return msg;
    },
  },
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import PortalVue from 'portal-vue';
import {ToastPlugin} from 'bootstrap-vue';
import Vuex from 'vuex';

import App from '@/views/Gate';
import router from '@/router';
import {BASE_STORE_DEF} from '@/global';
import '@/style/custom.scss';

Vue.use(VueRouter);
Vue.use(PortalVue);
Vue.use(ToastPlugin);
Vue.use(Vuex);
const store = new Vuex.Store(BASE_STORE_DEF);
new Vue({router, render: (h) => h(App), store}).$mount('#gate');

import VueRouter from 'vue-router';

// nested routes does not work for me
const routes = [
  {path: '/account/login', component: () => import('@/views/login'), name: 'login'},
  {
    path: '/account/reset/:uid/:token/',
    component: () => import('@/views/reset/Reset'),
    name: 'password-reset',
  },
  {
    path: '/account/reset/',
    component: () => import('@/views/reset/Request'),
    name: 'password-reset-request',
    props: true,
  },
  {path: '/account', redirect: {name: 'login'}},

  {path: '/i/overview', component: () => import('@/views/overview'), name: 'overview'},
  {path: '/i/properties', component: () => import('@/views/properties'), name: 'properties'},
  {path: '/i/actions', component: () => import('@/views/actions'), name: 'actions'},
  {path: '/i/intel', component: () => import('@/views/intel'), name: 'intel'},
  {path: '/i/info', redirect: {name: 'soon'}, name: 'info'},
  {path: '/i', redirect: {name: 'overview'}},

  {path: '/i/soon', component: () => import('@/components/Soon'), name: 'soon'},
];

const router = new VueRouter({routes, mode: 'history'});

export default router;
